import { Moon, Sun } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useEffect, useState } from "react";
import type { Lang } from "@/types";
import { useTranslations } from "@/i18n";

type Theme = "light" | "dark" | "system";

export function DarkModeDropdown({ lang }: { lang: Lang }) {
  const t = useTranslations(lang);
  const [theme, setThemeState] = useState<Theme>("light");

  useEffect(() => {
    const theme = (localStorage.getItem("theme") as Theme) ?? "light";
    setThemeState(theme);
  }, []);

  useEffect(() => {
    const listenSystem = (event: MediaQueryListEvent) => {
      if (theme === "system") {
        document.documentElement.classList[event.matches ? "add" : "remove"]("dark");
      }
    };

    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", listenSystem);

    return () => {
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", listenSystem);
    };
  }, [theme]);

  useEffect(() => {
    const isDark =
      theme === "dark" ||
      (theme === "system" && window.matchMedia("(prefers-color-scheme: dark)").matches);
    document.documentElement.classList[isDark ? "add" : "remove"]("dark");
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon">
          <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">
            {t("common.toggle")} {t("common.theme")}
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => setThemeState("light")}>
          {t("common.light")}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setThemeState("dark")}>
          {t("common.dark")}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setThemeState("system")}>
          {t("common.system")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
